/* css du site SNCM
/* 24/04/2023 Christine Delacourt : création */
* {
    margin: 0;
    padding: 0;
    text-decoration:none;
    box-sizing:border-box;
}
link{
    display:unset;
}
img{
    padding:1%;
    opacity:1;
}
/* FONTE */
@font-face{
    font-family:'Raleway';
    src: url('../font/Raleway/Raleway-VariableFont_wght.ttf') format('truetype');
    font-family:'Lato';
    src: url('../font/Lato/Lato-Regular.ttf') format('truetype');
}
/* ANIMATIONS FORMULAIRES ADHESION : page NOUS REJOINDRE*/
@keyframes non {
    25% {
        background:  #e3002b;
        transform: translateX(3%);
    }
    75% {
        background:  #e3002b;
        transform: translateX(-3%);
    }
}
/* ANIMATIONS INFO LABAZ : page ACCUEIL*/
/* Texte défilant */ 
  .messagedefilant {
    display: block;
    margin: 2px auto;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 45px;
    border-top:hsl(239,83%,40%) solid 3px;
    border-bottom:hsl(239,83%,40%) solid 3px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .messagedefilant div {
    position: absolute;
    min-width: 100%; /* au minimum la largeur du conteneur */
    color:#1e21ac;
  }
   
  .messagedefilant div span, 
  .messagedefilant div:after {
    position: relative;
    display: inline-block;
    font-size: 2rem;
    white-space: nowrap;
    top:0;
    margin:2px 0;
  }
   
  .messagedefilant div span {
    animation: defilement1 20s infinite linear;
  }
   
  .messagedefilant div:after {
    position: absolute;
    top:0; left:0;
    content:attr(data-text);
    animation: defilement2 20s infinite linear;
  }
   
  @keyframes defilement1 {
    0% { margin-left: 0; }
    100% { margin-left: -100%;}
  }
   
  @keyframes defilement2 {
    0% { margin-left: 100%; }
    100% { margin-left: 0%; }
  }
/* TEXTE */
h1 { 
    color:#1e21ac;
    font-family:'Raleway';
    font-size: 22px; 
    text-align:center;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    padding: 6px; 
    margin:5px;
/*    border-radius:5px;*/
}
h2{
    color:#1e21ac;
    font-family:'Raleway';
    font-size: 22px; 
    text-align:center;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
}
h3{
    color:black;
    font-family:'Raleway';
    font-style:bold;
    font-weight:700;
    font-size:14px;
    line-height:21px;
    text-align:center;
    vertical-align:middle;
}
h3 a { text-decoration: none }
p{ 
    color:hsl(239,83%,40%);
    display: block;
    width:calc(100% - 16px);
    padding:10px;
    background-color: transparent;
    font-style: normal;
    font-family: "Lato", Helvetica, Arial, Lucida, sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 27px;
    overflow-wrap: break-word;
    margin:0px 8px;    
    text-align: left;
    opacity:1;
}
blockquote
{
  font-style:normal;
  font-size:16px;
  font-family:'Raleway';
  text-align: center;
  color:#1e21ac;
  border-left:10px solid #e3002b;
  border-right:10px solid #e3002b;
  background-color:white;
  padding:0px 5px 0px 5px;;
}
ul, ol { margin: 0px; margin-bottom: 0px; line-height: 18px }

hr { display: block; height: 1px; margin: 0; _margin: -7px 0; border: 0; border-top: 1px dashed #999 }

a{
    color:darkmagenta;
    font-family:'Lato',sans-serif;
    text-align: left;
    font-style: normal;
    font-weight:800;
    font-size:24px;
    line-height:1.5em;
}
a:focus{
    color:#1e21ac;
    background-color:white;
}
.fleche{
    background-color: white;
    width: 30px;
    height: 40px;
}
.fleche .fa-solid{
    color:#1e21ac;
}
.fa-arrow-left{
    position: relative;
    left:-10px;
}
.fa-arrow-right{
    position: relative;
    right:10px;
}
.page-active{
    width:200px;
}
.background{
    background-image:url("../images/blog/vieduclub/20230603decouverte.jpg");
    background-attachment:fixed;
    opacity:1;
}
.banniere{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
/*    background-image: url("../images/logos/Banniere.JPG");
    background-repeat:no-repeat;
    background-position:center;*/
}
.logo img{ /* Logo SNCM */
    width:170px;
    padding: 0;
    object-fit: contain;
}
.social img{
    width: 30px;
}
.banniere p{ 
    background-color:white;
    color:#1e21ac;
}
.menu{
    position: relative;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    width:calc(100% - 238px);
    height:95px;
}
.menu p{
    position: absolute;
    z-index:2;
    display:block;
    width:50px;
    height:30px;
    color:#1e21ac;
    padding:0;
    margin:0; 
    background-color:transparent;
    border-radius:0;
    display: none;
}
li .fa-sharp,.fa-solid{   
    display:inline-block;
    width:22px;
    height:22px;
    font-weight:bolder;
    text-align:left;
    vertical-align:middle;
    padding:4px 15px 4px 15px;
    color:white;
    background-color:transparent;
}
.menu p .fa-bars{   
    color:#1e21ac;
    background-color:white;
    display:none;
}
ul{
    display:flex;
    flex-direction:row;
    justify-content:space-around;  
    align-items:center;
    list-style-type:none;
    width:100%;
    background-color:none;
}
li a{
    display: inline-block;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:68px;
    height:52px;
    color:white;
    overflow: hidden;
    text-decoration: none;
    border: 4px solid #ffffff;
/*    border-radius: 30px;*/
    transform-origin:left;
    transition: all 0.3s ease-out;
}
.accueil{
    box-shadow: 0px 0px 3px hsl(349,100%,45%);
    background:linear-gradient(8deg, hsl(349,100%,80%) 1%, hsl(349,100%,70%) 50%, hsl(349,100%,55%) 100%);
}
.activites{
    box-shadow: 0px 0px 3px hsl(302,100%,45%);
    background:linear-gradient(8deg, hsl(302,100%,90%) 1%, hsl(302,100%,70%) 50%, hsl(302,100%,45%) 100%);
}
.liste-menu li .blog,.adressemail{
    box-shadow: 0px 0px 3px hsl(239,83%,40%);
    background:linear-gradient(8deg, hsl(239,83%,80%) 1%, hsl(239,83%,60%) 88%, hsl(239,83%,40%) 100%);
}
.infopratiques{
    box-shadow: 0px 0px 3px hsl(177,100%,45%);
    background:linear-gradient(8deg, hsl(177,100%,90%) 1%, hsl(177,100%,70%) 50%, hsl(177,100%,45%) 100%);
}
.tarifs{
    box-shadow: 0px 0px 3px hsl(115,100%,45%);
    background:linear-gradient(8deg, hsl(115,100%,90%) 1%, hsl(115,100%,70%) 50%, hsl(115,100%,45%) 100%);
}
.adhesion{
    box-shadow: 0px 0px 3px hsl(115,100%,45%);
    background:linear-gradient(8deg, hsl(62,100%,55%) 1%, hsl(62,100%,45%) 50%, hsl(62,100%,35%) 100%);
}
li .trombinoscope{
    box-shadow: 0px 0px 3px hsl(28,100%,45%);
    background:linear-gradient(8deg, hsl(28,100%,90%) 1%, hsl(28,100%,70%) 50%, hsl(28,100%,45%) 100%);
}
.mobile{
    box-shadow: 0px 0px 3px hsl(349,100%,45%);
    background:linear-gradient(8deg, hsl(349,100%,80%) 1%, hsl(349,100%,70%) 50%, hsl(349,100%,55%) 100%);
}
.tarifs .fa-euro-sign{
    display: block;
    height:33px;
    color:white;
    margin-left:7px; 
}
.fa-solid{
    display: block;
    height:33px;
    color:white;
    margin:0 2px;    
}
.menu-texte{
    display: block;
    color: rgb(255, 255, 255);
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 20px;
    font-weight: 700;
    padding-left:25px;
    line-height: 20px;
    opacity:1;
    text-shadow: none;
    transition:all 0.3s ease-in-out 0.3s;
}
li a:hover{
    width:200px;
}
li a:hover + .menu-texte{
    opacity:1;
}
/* réseaux sociaux */
.reseauxsociaux{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 95px;
}
.social{
    display:flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
}
/* CALENDRIER */
.ephemeride{
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    gap:10px;
}
.pagecalendrier{
    float: left;
    width:100px;
    background-image:url("../images/ressorts.jpg");
    object-fit: cover;
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.8);
    height: auto;
    text-align:center;
    box-shadow: 6px 6px 5px gray;
    margin:1%;
    padding: 0;

}
.joursemaine{
    color:#1e21ac;
    font-weight:700;
    font-size:13px;
    line-height:20px;
    padding-top:20px;
}.jourmois{
    color:#e3002b;
    font-weight:700;
    font-size:50px;
    line-height:40px;
}.mois{
    color:#1e21ac;
    font-weight:700;
    font-size:13px;
    line-height:13px;
    padding-bottom:5px;
}
.annee{
    color:#e3002b;
    font-weight:700;
    font-size:13px;
    line-height:13px;
    padding-bottom:5px;
}
/*TARIFS*/
#tarifs { margin: 0 auto; width: 70%; position: relative; text-align: center }
#tarifs p{font-size: 16px;font-weight: bold;line-height: 19px;}
td th { border: 1px solid rgb(190, 190, 190); padding: 10px;font-size: 14px;font-weight: bold;line-height: 17px; }
td { text-align: center;  height : 30px;font-size: 14px;font-weight:bold;line-height: 18px; }
tr:nth-child(even) { background-color: rgba(255, 255, 255, 0.8); }
tr:nth-child(odd) { background-color: rgba(200, 200, 200, 0.8); }
th[scope="col"] { background-color: #1e21ac; color: #fff; font-size: 15px;font-weight:bold;line-height: 17px;}
th[scope="colprix"] { background-color: #1e21ac; color: #fff; min-width: 30px;font-size: 14px;font-weight:bold;line-height: 17px;}
th[scope="row"]:nth-child(even) { background-color: rgba(255, 255, 255, 0.8); text-align: left;font-size: 14px;font-weight:bold;line-height: 17px;}
th[scope="row"] {text-align: left}
caption {padding: 10px;caption-side: bottom;font-size: 14px;font-weight:bold;line-height: 17px;}
table {margin:10px 0 10px 0; border-collapse: collapse;border: 2px solid rgb(200, 200, 200);letter-spacing: 1px;font-family: sans-serif;}
/*  PAGE CONTAINERS*/
.container-page-accueil,.container-page-trombinoscope{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    background-color: transparent;
    opacity: 1;
    height:auto; 
    position:relative;
}
.container-page-infos{
    display:flex;
    justify-content:space-between;
    height:calc(100vh - 155px);
}
.container-page{
    display:flex;
    justify-content:space-between;
    margin:10px;
    height:calc(100vh - 179px);
}
.container-page-diplome{
display:flex;
justify-content:space-around;
height:calc(100vh - 95px);
}
.container-page-diplome img{
height:calc(100vh - 95px);
}
.container-page-blog{
    display:flex;
    justify-content:space-between;
    height:auto;   
    width: 100%;

}
.container-babyski{
    display:flex;
    justify-content:space-between;
    align-items: center;
    margin:10px;
    height:100vh; 
}
.container-page-activites{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content:space-around;
    align-items: center;
    padding:2vh 15vw;
    gap:20px;
    min-height:calc(100vh - 95px); 
    height: auto;
}
.container-decouverte{
    display:flex;
    justify-content:space-between;
    margin:10px;
    max-height:calc(100vh-52px);  
}
.container-page-2008{
    display:flex;
    flex-wrap: wrap;
    justify-content:space-between;
    margin:10px;
    min-height:100vh;
}
.container-page-2008 img{
    width: 175px;;
    height:123px;
/*    border-radius:5px;  */
    margin:0px 5px;  
}
.premiere-ligne-page{
    display:flex;    
    flex-wrap:nowrap;
    justify-content:center;
    flex-direction:row;
    width:100%;	
    height:auto;
/*    background:linear-gradient(to right,hsl(239,83%,90%) 1%, hsl(239,83%,90%) 70%, hsl(349,100%,85%) 70%, hsl(349,100%,95%) 97%,hsl(349,100%,45%) 97%,hsl(349,100%,45%) 100%);  
    border-bottom-right-radius:60%;
    overflow: hidden;*/
    background:transparent;
    position:relative;
    z-index:2;
}
.ligne-page{
    overflow:visible;
    border:hsl(239,83%,40%) solid 3px;
    border-radius:10px;
    padding:2px;
    /*    On met une opacité de 0.6 sur le background  */
    background-color: rgba(255, 255, 255, 0.7);
    margin: 1%;
}
/*.colonne-page-accueil-gauche{
    display: flex;
    justify-content:flex-end;
    width:30%;
    height:auto;
    border-top:50px solid hsl(239,83%,90%);
}*/
.premiere-ligne-page video{
    display: block;	
    width:30%;
    height:auto;
    padding:1%;
/*    border-top-left-radius:80vh;   */
}
.colonne-page-accueil-droite{
    display: flex;
    justify-content: center;
    align-items: center;
    width:70%;
/*    border-top:50px solid hsl(349,100%,45%);*/
}
.premiere-ligne-page video{
    z-index: 4;
    width:40%;
    transform: scale(1);
    transform-origin:0% 0%;
    transition:transform 1000ms;
    padding:1%;
}
.premiere-ligne-page img{
    z-index: 4;
    object-fit:contain;
    height:auto;
    transform: scale(1);
    transform-origin:100% 0%;
    transition:transform 1000ms;
    padding:1%;
}
.imageright img{
    float:right;
    width: 20%;
}
.left{
    float:left;
    transform: scale(1);
    transform-origin:0% 0%;
    transition:transform 1000ms;
    padding:1%;
}
.right{
    float:right;
    transform: scale(1);
    transform-origin:100% 0%;
    transition:transform 1000ms;
    padding:1%;
}
.left:hover,.left:active{
    position:relative;
    z-index:4;
    transform:scale(3);
}
.right:hover,.right:active{
    position:relative;
    z-index:4;
    top: 0px; right: 0px;
    transform:scale(3);
}
video:hover,video:active{
    position:absolute;
    z-index:4;
    transform:scale(3);
}
.gros-titre{
    display: block;
    text-align: center;
    font-family:"Oswald", Helvetica, Arial, Lucida, sans-serif;
    color:#1e21ac; 
    font-weight:600;
    text-transform: uppercase;
    font-size:4.5vw;
    letter-spacing: 0.7vw;
    line-height: 5.5vw;
    padding-bottom:10vw;
    padding-right: 5%;
}
.deuxieme-ligne-page{
    margin-top:2px;
    display:flex;    
    flex-wrap:nowrap;
    justify-content:space-around;
    flex-direction:row;
    width:100%;	
    height:auto;
}
.premiere-ligne-page p{
    color:hsl(239,83%,40%);
    display: block;
    width:calc(100% - 16px);
    border:hsl(239,83%,40%) solid 3px;
    border-radius:10px;
    padding:2px;
/*    border-top-right-radius:100px;
    border-bottom-right-radius:0;*/
    background-color: rgba(255, 255, 255, 0.8);
    font-family: "Lato", Helvetica, Arial, Lucida, sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 27px;
    overflow-wrap: break-word;
    text-align: left;
}
.premiere-ligne-page img:hover,.premiere-ligne-page img:active{
        transform:scale(3);
        height:auto;
}
.premiere-ligne-page video:hover,.premiere-ligne-page video:active{
    transform:scale(2.3);
    height:auto;
}
.colonne-page-accueil{
    display:flex;    
    flex-wrap:nowrap;
    justify-content:flex-start;
    flex-direction:column;
    width:100%;	
    height:calc(100vh - 95px);
}
.colonne-page-accueil-bas{
    display:flex;    
    flex-wrap:nowrap;
    justify-content:flex-start;
    flex-direction:column;
    width:100%;	
    height:50vh;
}
.troisieme-ligne-page{
    margin-top:10px;
    display:flex;    
    flex-wrap:nowrap;
    justify-content:flex-start;
    flex-direction:column;
    width:100%;	
    height:200px;
    overflow:hidden;
}
.pellicule{
    overflow: hidden;
    max-width:100%;
    height:20px;
}
.bord{
    width:5670px;
    height:20px;
    background-color: #EDEDED;
    background-image:url("../images/accueil/bordpellicule.jpg");
    background-repeat: repeat;
    background-size:30%;
    animation: defilement-photo 40s linear infinite;
}
@keyframes defilement-photo{
    0%{transform: translate(0,0);}
    100%{transform: translate(-2880px,0);} 
}
.film{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    background-color: black;
    gap:0px;
    height:150px;
    width:5670px;
    overflow:hidden;
}
.photosdefilantes img{
    width:200px;
    height:150px;
    border-left:black solid 5px;
    border-right:black solid 5px;
    animation:defilement-photo 40s linear infinite;
    padding: 0;
    object-fit: cover;
}  
/*.bord:hover,.photosdefilantes img:hover{
    animation-play-state: paused;
}*/
.carte-page-activites{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:320px;
    max-height:280px;
}
.bleu{
    color:hsl(239,83%,40%);
}
.bleu-twitter{
    color:#00aced;
}
.fond-bleu-facebook{
    width: 30px;
    height: 30px;
    text-align: center;
    background-color:#3b5998;
    border-radius: 2px;
}
.fa-square-instragram{
    width: 30px;
    height: 30px;
    color: black;
    position: relative;
  }
.blanc{
    color:#fff
}
.rouge{
    color:#e3002b;
}
.noir{
    color:black
}
.fond_bleu{
    background-color:hsl(239,83%,40%);
}
.fond_blanc{
    background-color:#fff;
}
.fond_rouge{
    background-color:hsl(349,100%,40%);
}
.bord_bleu{
    border:10px solid hsl(239,83%,40%);
}
.bord_blanc{
    border:10px solid #fff;
}
.bord_rouge{
    border:10px solid hsl(349,100%,40%);
}
.w20{
    width:20%;
}
.w30{
    width:30%;
}
.w33{
    width:33%;
}
.w40{
    width:40%;
}
.w50{
    width:50%;
}
.w60{
    width:60%;
}
.w66{
    width:66%;
}
.w98{
    width:98%;
}
.w100{
    width:100%;
}
.h25{
    height:25%;
}
.h33{
    height:33%;
}
.drapeau{
    width:30px;
    height:18px;
}
.drapeau img{
    width:30px;
    height:18px;
    object-fit:contain;
}
.drapeau a{
    font-size: 0;
}
.carte-page-activites img{
    object-fit:cover;
    width:300px;
    height:200px;
}
.carte-page-activites h2{
    display: block;
    font-family: "Oswald", Helvetica, Arial, Lucida, sans-serif;
    width:300px;
    height:80px;
    line-height:45px;
    border:none;
    color:white;
    text-align:center;
/*    border-radius:unset;*/
    border:0;
    margin:0;
}

.colonne-decouverte-gauche{
    display:flex;
    width:66%;
    height:auto;
    justify-content:flex-start;
    align-items: center;
    flex-wrap:wrap;
}
.colonne-decouverte-droite{
    display:flex;
    width:33%;
    height:auto;
    justify-content:flex-start;
}
.colonne-decouverte-droite .consigne
{
    width:100%;
    height: auto;
    object-fit:contain;
}
.carte-decouverte{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    aspect-ratio: 4/3;
}
.colonne-decouverte-gauche img{
    object-fit:cover;
/*    border-bottom-right-radius:100px;*/
    height:100%;
}
.colonne-page a{
    color:#e3002b;
}
.colonne-page img,.colonne-page video{
    object-fit:cover;
    max-height:100vh;
    width: 100%;
/*    border-top-right-radius:100px;*/
}
/*p {
    color:#fff;
    background:hsl(239,83%,40%);
    display: block;
    width:calc(100% - 40px);
    width: 100%;
    padding:2%;
    font-family: "Lato", Helvetica, Arial, Lucida, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 27px;
    overflow-wrap: break-word;
    text-align:left;
}*/
.colonne-page-babyski{
    max-height: 100vh;
}
.container-page-adhesion{
	display:flex;
    flex-direction:row;
    /*    si tarif famille
    justify-content:space-between;*/
    justify-content: space-evenly;
}    
.colonne-page-adhesion
{
    width:49%;
    justify-items:center;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items: center;
    min-height:100vh;
    background-color:transparent;
}
.formulaireindividuel,.tariffamille{
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-content:space-evenly;
    flex-wrap: wrap;
    align-items:center;
    width:100%;
    background-color:#f5f5f5;
    opacity: 0.9;
    padding: 1vw 0;
}
.boutons-famille{
width:100%;
    justify-content:flex-start;
	flex-wrap:nowrap;
}
.tariffamille{
    justify-content:flex-start;
}
.colonne-page-adhesion h1{
    width:92%;
    border:0;
}
select{
    height:40px;
    transition: background-color 500ms;
}
label{
    line-height:1.2rem;
    font-size:1rem;
    color:#1e21ac;
    text-align: left;
    margin-top:10px 0;
    max-width: 100%;
    font-weight: bold;
    font-family:Roboto,Helvetica,Arial,sans-serif;
    vertical-align:bottom;
}
textarea,input{
    height:36px;
    background-color: #fff;
}
.civilite,.code,.identite,.adresse,.ville,.telephone,.mail,.datenaissance,.licence,.etudiant,.carte_etudiant{
    width:46.5%;
    margin-bottom: 20px;
}
.nomfamille,.nbenfmoins18ans,.nbfemme,.nbetudiant,.nbhomme,.villefamille,.nbhomme60ans{
display:flex;
    justify-content:space-between;
    width:93%;
    margin-bottom: 10px;
}
.calculer,.prix,.soumettre,.calculertariffamille,.prixfamille,.soumettrefamille{
    width: 31%;
}
.boutons-famille{
display:flex;
justify-content:space-between;
width:100%;
}
.calculertariffamille,.prixfamille,.soumettrefamille{
display:inline-block;
    width:32%;
}
.civilite *,.code *,.identite *,.adresse *,.ville *,.telephone *,.mail *,.datenaissance *,.licence *,.etudiant *,.carte_etudiant *,.calculer *,.prix *,.soumettre *{
    width:100%;
}
.calculer *,.prix *,.soumettre *{
    width:32%;
}
.nomfamille *,.nbenfmoins18ans *,.nbfemme *,.nbetudiant *,.nbhomme *,.villefamille *,.nbhomme60ans *{
    width:46.5%;
    margin-bottom: 20px;
}
.tariffamille label{
width:75%;
}
em {
    font-weight: bold;
    font-style: normal;
    color: #e3002b;
    }
input:focus {
    background: #eaeaea;
    }
input:not(:focus):invalid {
                color: #fff;
                animation: non 200ms cubic-bezier(.4,.1,.6,.9) 2;
            }

.colonne-page-decouverte img{
    object-fit:cover;
    width:30vw;
/*    border-top-right-radius:100px;*/
}
.container-page-infos .colonne-page-gauche{
    width:66%;
    }
.container-page-infos .colonne-page-gauche img{
    width:100%;
    }
.container-page-infos .colonne-page-droite{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:33%;
    }
.container-page-infos .colonne-page-droite p{
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    overflow-wrap: break-word;
    text-align:left;
    margin:0;
            }
#tarifs p{
    width: 100%;
    margin:0;
}
.container-page-trombinoscope .trombinoscope{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    margin:10px;
}
.container-page-trombinoscope .trombinoscope img{
    border-radius:50%;
    width:200px;
    height:200px;
    border:3px solid #1e21ac; 
    object-fit:cover;
}
.container-page-diplome img{
object-fit: contain;
}
/* FOOTER */
footer{
    width:100%;
    height:60px;    
    margin-top:2px;
}
.container-footer{
    display:flex;
    justify-content:space-around;
    width:100%;
}
.colonne-footer{
    display:flex;
    justify-content:space-between;
    height:46px;
}
.colonne-footer a{
    display:block;
    color:white;
    font-family:'Raleway';
    font-style:Regular;
    font-weight:700;
    font-size:14px;
    text-align:center;
    background-color:#1e21ac;
    border-radius:0px 15px 15px 0px;
    padding: 10px 10px 10px 10px;
}
.colonne-footer img{
    display:block;
    height:46px;
    background-color:white;
    padding:5px 10px 5px 10px;
    border-radius:15px 0px 0px 15px;
    border:2px #1e21ac solid;
    object-fit:cover;
}
/* Nouvelles règles si la fenêtre fait moins de 1100px de large */
/* pour utiliser l'unité vw on divise par 9.92px */
@media screen and (max-width: 1100px){
#tarifs{
    width: 100%;
    margin:0;
}
}
/* Nouvelles règles si la fenêtre fait entre 768px et  992px de large */
/* pour utiliser l'unité vw on divise par 9.92px */
@media screen and (max-width: 992px){
    .container-page{
        flex-wrap: wrap;
        overflow: hidden;
    }
.container-decouverte{
    max-height:calc(100vh-52px); 
}
.container-page-blog{
    flex-wrap: wrap; 
    overflow: visible;
    z-index: 2;
}
.colonne-decouverte-droite{
    width:50%;
    min-height:unset;
}
.colonne-decouverte-gauche{
    width:50%;
    min-height:unset;
}
.carte-decouverte{
    width:100%;
    height: none;
}  
.container-babyski{
    flex-wrap: wrap;
    min-height:auto; 
} 
.container-page-adhesion{
        flex-direction:column;
        height:auto;
    }
.formulaireadhesion{
    width:100%;
padding:0;
}
.page-active{
    width:68px;
}
.colonne-page-adhesion{
    width:100%;
    height:auto; 
}
.colonne-page{
    width:50%;
}
.colonne-page p{
    overflow: auto;
}
footer{
    position:relative;
    top:unset;
}
.container-footer{
    flex-wrap:wrap;
}
}
/* Nouvelles règles si la fenêtre fait au plus 768px de large */
/* pour utiliser l'unité vw on divise par 3.75px */
@media screen and (max-width: 768px){
    .container-page{
        flex-direction:column;
        overflow:unset;
        max-height:unset;
    }
    .logo img{ /* Logo SNCM */
        padding: 0 0 1% 0;
    }
    .container-page-blog{
        flex-direction: column;
        justify-content:flex-start;
        }
    .reseauxsociaux{
        flex-direction:row;
        width: calc(100% - 218px);
        align-items: center;
    }
    .premiere-ligne-page{
    /*    border-bottom-right-radius:15%;*/
        height: auto;
    }
    .premiere-ligne-page video:hover,.premiere-ligne-page video:active{
        transform:scale(2.5);
        height:auto;
    }
    .premiere-ligne-page img:hover,.premiere-ligne-page img:active{
        transform:scale(5);
        height:auto;
    }
    .ligne-page img:hover,.ligne-page img:active{
        transform:scale(3.3);
        height:auto;
    }
    .ligne-page video:hover,.ligne-page video:active{
        transform:scale(3.3);
        height:auto;
    }
    .colonne-page{
        width:100%;	
        height: auto;
    }
    .colonne-page-accueil-gauche{
        width:100%;
        border-top:none;
    }
    .colonne-page-accueil-droite{
        display: none;
    }
    .deuxieme-ligne-page{
        flex-direction: column;
    }
    .container-page-2008{
        justify-content: center;
        align-items: center;
    }
    .container-page-2008 img{
        width: 100%;
        height:auto;
    }
    p{
        width:98%;
        margin-left: 0;
        margin-right: 0;
    }
    h2{
        margin-left: 0;
        margin-right: 0;
    }
    .carte-decouverte img{
        width: 100%;
    }
    .deuxieme-ligne-page p{
        width:100%;
        margin:0;
    /*    border-top-right-radius:100px;
        border-bottom-left-radius:0;*/
    }
    .deuxieme-ligne-page img{
        width:100%;
    /*    border-top-left-radius:0;
        border-bottom-left-radius:100px;*/
        height:auto;
    }
    .colonne-page-accueil-gauche .colonne-page{
        width:100%;
    }
    .colonne-page-accueil-droite{
        display:none;
    }
    .menu{
        position:absolute;
        z-index:3;
        width:68px;
        height: 30px;
        top:95px;
        left: 0px;  
    }
    .menu p{   
        display:unset;
    }
    .menu p .fa-sharp,.menu p .fa-solid,.menu p .fa-bars{   
        display:inline-block;
        width:68px;
        height:33px;
        font-weight:bolder;
        text-align:center;
        vertical-align:middle;
        padding:4px 15px 4px 15px;
        color:#1e21ac;
        background-color:white;
        cursor: pointer;
    }
    .liste-menu{
        position: absolute;
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start;
        top:33px;
        left:0;
        width:auto;
        height:auto;
        cursor: pointer;
        display: none;
    }
    .menu:hover .liste-menu{
        display: unset;
        }  
        .ligne-page{
            width: 98%;
        } 
    .container-page-activites{
        flex-direction:column;
        flex-wrap: wrap;
        justify-content:flex-start;
        margin:0;
        padding:0;
        gap:0;
    }
.container-decouverte{
    flex-direction: column; 
}
.colonne-decouverte-gauche{
    width:100%;
}
.colonne-decouverte-droite{
    width:100%;
}
.container-page-infos{
    flex-direction: column;
    height:auto;
}
    .container-page-infos .colonne-page-gauche{
    width:100%;
    }
    .container-page-infos .colonne-page-droite{
    width:100%;
    }
    .container-footer{
        flex-direction: column;
    }
    .colonne-footer{
        justify-content:center;
        
    }
    .pagecalendrier{
        width:50px;
    }
    .joursemaine{
        color:#1e21ac;
        font-weight:700;
        font-size:10px;
        line-height:15px;
        padding-top:20px;
    }.jourmois{
        color:#e3002b;
        font-weight:700;
        font-size:30px;
        line-height:30px;
    }.mois{
        color:#1e21ac;
        font-weight:700;
        font-size:10px;
        line-height:10px;
        padding-bottom:0px;
    }
    .annee{
        color:#e3002b;
        font-weight:700;
        font-size:13px;
        line-height:13px;
        padding-bottom:0px;
    }
}